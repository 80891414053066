import { ValueOf } from 'ts-essentials';

export const LOCAL_CLIENT_PORT = 3000;
export const LOCAL_API_PORT = 8000;

type PathParameterSelectors = Omit<typeof PATH_PARAMETER_SELECTORS, 'prototype'>;
export type PathParameterSelector = ValueOf<PathParameterSelectors>;

export class PATH_PARAMETER_SELECTORS {
    static readonly ACCESS_TYPE_CODE = ':accessTypeCode';
    static readonly PARTNER_CODE = ':partnerCode';
    static readonly REPORT_CATEGORY_CODE = ':reportCategoryCode';
    static readonly REPORT_ID = ':reportId';
    static readonly FREQUENCY_TYPE_CODE = ':frequencyTypeCode';
    static readonly USER_ID = ':userId';
    static readonly INVITATION_ID = ':invitationId';
    static readonly FREQUENTLY_ASKED_QUESTION_ID = ':frequentlyAskedQuestionId';
    static readonly RECAPTCHA_WIDGET_RESPONSE = ':reCaptchaWidgetResponse';
    static readonly FORM_RECORD_ID = ':recordId';
    static readonly DASHBOARD_ID = ':dashboardId';
    static readonly EMAIL = ':email';
    static readonly TENANT_ID = ':tenantId';
    static readonly IS_THOUGHTSPOT_USER = ':isThoughtSpotUser';
    static readonly PINBOARD_ID = ':pinboardId';
    static readonly GUID = ':guid';
    static readonly BANNER_ID = ':bannerId';
    static readonly REPORT_MONITOR_ID = ':reportMonitorId';
    static readonly CATALOG_ID = ':catalogId';
}

export class API_PATHS {
    static readonly LOGIN = '/api/oauth2/authorization/copp';
    static readonly LOGIN_REDIRECT = '/api/login/redirect';

    static readonly GET_ENVIRONMENT_CONTEXT = '/api/environment-context';
    static readonly GET_USER = '/api/user';
    static readonly GET_IS_USER_AUTHENTICATED = '/api/user/authenticated';

    static readonly GET_ALL_PARTNERS = '/api/partners';
    static readonly GET_ALL_MARKETING_CONTENT_PARTNERS = '/api/marketing/partners';
    static readonly GET_REPORT_CATEGORIES_FOR_PARTNER = '/api/categories/:accessTypeCode/:partnerCode';
    static readonly GET_REPORTS = '/api/reports/:accessTypeCode/:partnerCode/:reportCategoryCode';
    static readonly VIEW_REPORT = '/api/reports/view/:reportId';
    static readonly DOWNLOAD_REPORTS = '/api/reports/download';

    static readonly GET_ALL_PARTNERS_ADMIN = '/api/admin/partners';
    static readonly GET_PARTNER_ADMIN = '/api/admin/partners/:partnerCode';
    static readonly GET_ALL_REPORT_CATEGORIES_ADMIN = '/api/admin/categories';
    static readonly GET_ALL_FREQUENCY_TYPES_ADMIN = '/api/frequency-types';
    static readonly GET_ALL_USERS_ADMIN = '/api/users';
    static readonly GET_SAAS_USERS = '/api/saas/user/:tenantId';
    static readonly GET_ALL_SAAS_USERS = '/api/saas/user/:tenantId/all';
    static readonly GET_ALL_SAAS_USER_ROLES = '/api/saas/role';
    static readonly GET_ALL_SAAS_USER_INVITATIONS = '/api/saas/user/:tenantId/invitations';
    static readonly UPDATE_CURRENT_SAAS_USER = '/api/saas/user/updateSelf'
    static readonly GET_EXTERNAL_USERS_ADMIN = '/api/external-users';
    static readonly GET_ALL_FREQUENTLY_ASKED_QUESTIONS_ADMIN = '/api/faqs';
    static readonly GET_TENANTS = '/api/saas/tenant'
    static readonly CREATE_PARTNER = '/api/partners';
    static readonly CREATE_REPORT_CATEGORY = '/api/categories';
    static readonly CREATE_FREQUENCY_TYPE = '/api/frequency-types';
    static readonly CREATE_USER = '/api/users';
    static readonly INVITE_SAAS_USER = '/api/saas/user/tenantId/:tenantId/tsUser/:isThoughtSpotUser/invite';
    static readonly CREATE_FREQUENTLY_ASKED_QUESTION = '/api/faqs';
    static readonly EDIT_PARTNER = '/api/partners';
    static readonly EDIT_REPORT_CATEGORY = '/api/categories';
    static readonly EDIT_FREQUENCY_TYPE = '/api/frequency-types';
    static readonly EDIT_USER = '/api/users';
    static readonly EDIT_SAAS_USER_ROLES = '/api/saas/user/:tenantId/:userId/roles/tsUser/:isThoughtSpotUser/:email';
    static readonly EDIT_FREQUENTLY_ASKED_QUESTION = '/api/faqs';
    static readonly DELETE_PARTNER = '/api/partners/:accessTypeCode/:partnerCode';
    static readonly DELETE_REPORT_CATEGORY = '/api/categories/:reportCategoryCode';
    static readonly DELETE_REPORT = '/api/reports/:reportId';
    static readonly DELETE_FREQUENCY_TYPE = '/api/frequency-types/:frequencyTypeCode';
    static readonly DELETE_SAAS_USER = '/api/saas/user/:tenantId/:userId/email/:email';
    static readonly DELETE_SAAS_USER_INVITATION = '/api/saas/user/:tenantId/invitations/:invitationId/email/:email';
    static readonly DELETE_USER = '/api/users/:userId';
    static readonly DELETE_FREQUENTLY_ASKED_QUESTION = '/api/faqs/:frequentlyAskedQuestionId';

    static readonly GET_FREQUENTLY_ASKED_QUESTIONS = '/api/faqs';
    static readonly GET_REPORTABLE_ISSUES = '/api/reasons';
    static readonly SUBMIT_REPORT_AN_ISSUE_FORM = '/api/contact-us';
    static readonly LOGOUT = '/api/logout';

    static readonly VALIDATE_RECAPTCHA = '/api/validate-recaptcha';

    static readonly GET_ALL_CUSTOMER_INVOICES = '/api/form/user-invoices';
    static readonly GET_ALL_WALMART_DISPUTES = '/api/form/disputes';
    static readonly GET_HISTORIC_WALMART_DISPUTES = '/api/form/disputes/history';
    static readonly CREATE_CUSTOMER_INVOICE = '/api/form/user-invoices';
    static readonly CREATE_WALMART_DISPUTES = '/api/form/disputes';
    static readonly EDIT_CUSTOMER_INVOICE = '/api/form/user-invoices';
    static readonly EDIT_WALMART_DISPUTES = '/api/form/disputes';
    static readonly DELETE_CUSTOMER_INVOICE = '/api/form/user-invoices/:recordId';
    static readonly DELETE_WALMART_DISPUTES = '/api/form/disputes/:recordId';

    static readonly GET_DASHBOARD_BY_ID = '/api/dashboards/:accessTypeCode/:partnerCode/:dashboardId';
    static readonly GET_ALL_DASHBOARD_PARTNERS = '/api/dashboards/partners';
    static readonly GET_DASHBOARDS_FOR_PARTNER = '/api/dashboards/:accessTypeCode/:partnerCode';
    static readonly GET_ALL_DASHBOARDS_ADMIN = '/api/dashboards';
    static readonly CREATE_DASHBOARD = '/api/dashboards';
    static readonly EDIT_DASHBOARD = '/api/dashboards/:dashboardId';
    static readonly DELETE_DASHBOARD = '/api/dashboards/:dashboardId';
    static readonly GET_DASHBOARD_CONTENT_TYPES = '/api/dashboards/content-types/:accessTypeCode/:partnerCode'

    static readonly TRACK_DASHBOARD_VIEW = '/api/activity/track/dashboard-view/:dashboardId';
    static readonly TRACK_DASHBOARD_FAIL = '/api/activity/track/dashboard-fail/:dashboardId';

    static readonly GET_QUICKSIGHT_BOARD_INFO = '/api/quicksight/:dashboardId';
    static readonly GET_LATEST_MARKETING_IMAGE = '/api/marketing/latest/:partnerCode';
    static readonly UPLOAD_MARKETING_IMAGE = '/api/marketing/upload/:partnerCode';
    static readonly THOUGHTSPOT_EMBEDDED_AUTH_ENTITY = '/api/ThoughtSpotEmbed/:pinboardId';
    static readonly GET_THOUGHTSPOT_USER = '/api/thoughtspot/user?name=:email';
    static readonly CREATE_THOUGHTSPOT_USER = '/api/thoughtspot/user';
    static readonly GET_LATEST_PARTNER_LOGO = '/api/logo/:partnerCode';
    static readonly UPLOAD_PARTNER_LOGO = '/api/logo/:partnerCode';
    static readonly GET_CURRENT_BANNER = '/api/banner';
    static readonly CREATE_BANNER = '/api/banner';
    static readonly EDIT_BANNER = '/api/banner/:bannerId/edit';
    static readonly DISABLE_BANNER = '/api/banner/:bannerId/disable'

    static readonly GET_ALL_SCHEDULED_REPORT_MONITORS = '/api/report-monitors';
    static readonly CREATE_SCHEDULED_REPORT_MONITOR = '/api/report-monitors';
    static readonly UPDATE_SCHEDULED_REPORT_MONITOR = '/api/report-monitors/:reportMonitorId';
    static readonly DELETE_SCHEDULED_REPORT_MONITOR = '/api/report-monitors/:reportMonitorId';

    static readonly GET_DATASET = '/api/datasets/:catalogId';
}

type ClientPaths = Omit<typeof CLIENT_PATHS, 'prototype'>;
export type ClientPath = ValueOf<ClientPaths>;

export class CLIENT_PATHS {
    static readonly HOME_PAGE = '/';
    static readonly SELECT_PARTNER_PAGE = '/partners';
    static readonly SELECT_TENANT_PAGE = '/tenants';
    static readonly SELECT_REPORT_CATEGORY_PAGE = '/partners/:accessTypeCode/:partnerCode/report-categories';
    static readonly DOWNLOAD_REPORTS_PAGE = '/partners/:accessTypeCode/:partnerCode/report-categories/:reportCategoryCode/reports';
    static readonly MARKETPLACE_PAGE = '/marketplace';
    static readonly MARKETING_SELECT_PARTNER_PAGE = '/marketing';
    static readonly MANAGE_MARKETING_CONTENT_PAGE = '/marketing/latest/:partnerCode';
    static readonly SELECT_OPERATIONAL_FORM_PAGE = '/forms';
    static readonly CUSTOMER_INVOICE_FORM_PAGE = '/forms/customer-invoice';
    static readonly CREATE_CUSTOMER_INVOICE_PAGE = '/forms/customer-invoice/new';
    static readonly EDIT_CUSTOMER_INVOICE_PAGE = '/forms/customer-invoice/:recordId/edit';
    static readonly WALMART_DISPUTE_FORM_PAGE = '/forms/walmart-disputes';
    static readonly CREATE_WALMART_DISPUTE_PAGE = '/forms/walmart-disputes/new';
    static readonly EDIT_WALMART_DISPUTE_PAGE = '/forms/walmart-disputes/:recordId/edit';

    static readonly ADMIN_PAGE = '/admin';
    static readonly MANAGE_PARTNERS_PAGE = '/admin/partners';
    static readonly MANAGE_REPORT_ALERTS_PAGE = '/admin/report-alerts';
    static readonly MANAGE_REPORT_CATEGORIES_PAGE = '/admin/report-categories';
    static readonly MANAGE_FREQUENCY_TYPES_PAGE = '/admin/frequency-types';
    static readonly MANAGE_EXTERNAL_USERS_PAGE = '/admin/users';
    static readonly MANAGE_SAAS_USERS_PAGE = '/admin/:tenantId/saas-users';
    static readonly MANAGE_SAAS_USER_INVITATIONS_PAGE = '/admin/:tenantId/saas-users/invitations';
    static readonly MANAGE_FREQUENTLY_ASKED_QUESTIONS_PAGE = '/admin/faqs';
    static readonly CREATE_PARTNER_PAGE = '/admin/partners/new';
    static readonly CREATE_REPORT_CATEGORY_PAGE = '/admin/report-categories/new';
    static readonly CREATE_FREQUENCY_TYPE_PAGE = '/admin/frequency-types/new';
    static readonly CREATE_FREQUENTLY_ASKED_QUESTION_PAGE = '/admin/faqs/new';
    static readonly CREATE_USER_PAGE = '/admin/users/new';
    static readonly INVITE_SAAS_USER_PAGE = '/admin/:tenantId/saas-users/new';
    static readonly EDIT_PARTNER_PAGE = '/admin/partners/:accessTypeCode/:partnerCode/edit';
    static readonly EDIT_REPORT_CATEGORY_PAGE = '/admin/report-categories/:reportCategoryCode/edit';
    static readonly EDIT_FREQUENCY_TYPE_PAGE = '/admin/frequency-types/:frequencyTypeCode/edit';
    static readonly EDIT_USER_PAGE = '/admin/users/:userId/edit';
    static readonly EDIT_SAAS_USER_PAGE = '/admin/:tenantId/saas-users/edit/:userId';
    static readonly EDIT_FREQUENTLY_ASKED_QUESTION_PAGE = '/admin/faqs/:frequentlyAskedQuestionId/edit';
    static readonly MANAGE_DASHBOARDS_PAGE = '/admin/dashboards';
    static readonly MANAGE_BANNER_PAGE = '/admin/banner'

    static readonly DASHBOARDS_SELECT_PARTNER_PAGE = '/dashboards/partners';
    static readonly DASHBOARDS_SELECT_PAGE = '/dashboards/:accessTypeCode/:partnerCode';
    static readonly THOUGHTSPOT_LIVEBOARD_PAGE = '/dashboards/thoughtspot/:accessTypeCode/:partnerCode/:pinboardId';
    static readonly RSTUDIO_LIVEBOARD_PAGE = '/dashboards/rstudio/:accessTypeCode/:partnerCode/:dashboardId';
    static readonly QUICKSIGHT_PAGE = '/dashboards/quicksight/:accessTypeCode/:partnerCode/:pinboardId';
    static readonly TABLEAU_PAGE = '/dashboards/tableau/:accessTypeCode/:partnerCode/:dashboardId';

    static readonly FREQUENTLY_ASKED_QUESTIONS_PAGE = '/faqs';
    static readonly MY_ACCOUNT_PAGE = "/my-account"
    static readonly REPORT_AN_ISSUE_PAGE = '/report-an-issue';
    static readonly LOGOUT = '/logout';
    static readonly LOGIN = '/login';


    static readonly CAPITAL_ONE_HOME_PAGE = 'https://www.capitalone.com/';
}

type PathToPageNameMap = Omit<typeof PATH_TO_PAGE_NAME_MAP, 'prototype'>;
export type PageName = ValueOf<PathToPageNameMap>;

export class PATH_TO_PAGE_NAME_MAP {
    static readonly [CLIENT_PATHS.HOME_PAGE] = 'Capital One Partner Portal';
    static readonly [CLIENT_PATHS.SELECT_PARTNER_PAGE] = 'Partners';
    static readonly [CLIENT_PATHS.SELECT_TENANT_PAGE] = 'Manage Users';
    static readonly [CLIENT_PATHS.SELECT_REPORT_CATEGORY_PAGE] = 'Report Categories';
    static readonly [CLIENT_PATHS.DOWNLOAD_REPORTS_PAGE] = 'Reports';
    static readonly [CLIENT_PATHS.MARKETPLACE_PAGE] = 'Marketplace';
    static readonly [CLIENT_PATHS.MARKETING_SELECT_PARTNER_PAGE] = 'Manage Marketing Content';
    static readonly [CLIENT_PATHS.MANAGE_MARKETING_CONTENT_PAGE] = 'Upload Content';
    static readonly [CLIENT_PATHS.SELECT_OPERATIONAL_FORM_PAGE] = 'Operational Forms';
    static readonly [CLIENT_PATHS.CUSTOMER_INVOICE_FORM_PAGE] = 'Customer Invoice Form';
    static readonly [CLIENT_PATHS.CREATE_CUSTOMER_INVOICE_PAGE] = 'Add Customer Invoice';
    static readonly [CLIENT_PATHS.EDIT_CUSTOMER_INVOICE_PAGE] = 'Edit Customer Invoice';
    static readonly [CLIENT_PATHS.WALMART_DISPUTE_FORM_PAGE] = 'Walmart Commercial Disputes Form';
    static readonly [CLIENT_PATHS.CREATE_WALMART_DISPUTE_PAGE] = 'Add Walmart Commercial Dispute';
    static readonly [CLIENT_PATHS.EDIT_WALMART_DISPUTE_PAGE] = 'Edit Walmart Commercial Dispute';

    static readonly [CLIENT_PATHS.ADMIN_PAGE] = 'Admin';
    static readonly [CLIENT_PATHS.MANAGE_PARTNERS_PAGE] = 'Manage Partners';
    static readonly [CLIENT_PATHS.MANAGE_REPORT_ALERTS_PAGE] = 'Manage Report Alerts';
    static readonly [CLIENT_PATHS.MANAGE_REPORT_CATEGORIES_PAGE] = 'Manage Report Categories';
    static readonly [CLIENT_PATHS.MANAGE_FREQUENCY_TYPES_PAGE] = 'Manage Frequency Types';
    static readonly [CLIENT_PATHS.MANAGE_EXTERNAL_USERS_PAGE] = 'Manage Internal Users';
    static readonly [CLIENT_PATHS.MANAGE_SAAS_USERS_PAGE] = 'Users';
    static readonly [CLIENT_PATHS.MANAGE_SAAS_USER_INVITATIONS_PAGE] = 'Manage Auth0 User Invitations';
    static readonly [CLIENT_PATHS.MANAGE_FREQUENTLY_ASKED_QUESTIONS_PAGE] = 'Manage Frequently Asked Questions';
    static readonly [CLIENT_PATHS.CREATE_PARTNER_PAGE] = 'Add Partner';
    static readonly [CLIENT_PATHS.CREATE_REPORT_CATEGORY_PAGE] = 'Add Report Category';
    static readonly [CLIENT_PATHS.CREATE_FREQUENCY_TYPE_PAGE] = 'Add Frequency Type';
    static readonly [CLIENT_PATHS.CREATE_USER_PAGE] = 'Add External User';
    static readonly [CLIENT_PATHS.INVITE_SAAS_USER_PAGE] = 'Invite a New User';
    static readonly [CLIENT_PATHS.CREATE_FREQUENTLY_ASKED_QUESTION_PAGE] = 'Add Frequently Asked Question';
    static readonly [CLIENT_PATHS.EDIT_PARTNER_PAGE] = 'Edit Partner';
    static readonly [CLIENT_PATHS.EDIT_REPORT_CATEGORY_PAGE] = 'Edit Report Category';
    static readonly [CLIENT_PATHS.EDIT_FREQUENCY_TYPE_PAGE] = 'Edit Frequency Type';
    static readonly [CLIENT_PATHS.EDIT_USER_PAGE] = 'Edit External User';
    static readonly [CLIENT_PATHS.EDIT_SAAS_USER_PAGE] = 'Update User Role';
    static readonly [CLIENT_PATHS.EDIT_FREQUENTLY_ASKED_QUESTION_PAGE] = 'Edit Frequently Asked Question';
    static readonly [CLIENT_PATHS.MANAGE_DASHBOARDS_PAGE] = 'Manage Dashboards';
    static readonly [CLIENT_PATHS.MANAGE_BANNER_PAGE] = 'Manage Banner';

    static readonly [CLIENT_PATHS.DASHBOARDS_SELECT_PARTNER_PAGE] = 'Partners';
    static readonly [CLIENT_PATHS.DASHBOARDS_SELECT_PAGE] = 'Dashboards';
    static readonly [CLIENT_PATHS.THOUGHTSPOT_LIVEBOARD_PAGE] = 'ThoughtspotLiveboard';
    static readonly [CLIENT_PATHS.RSTUDIO_LIVEBOARD_PAGE] = 'RStudioLiveboard';
    static readonly [CLIENT_PATHS.TABLEAU_PAGE] = 'Tableau';

    static readonly [CLIENT_PATHS.FREQUENTLY_ASKED_QUESTIONS_PAGE] = 'Frequently Asked Questions';
    static readonly [CLIENT_PATHS.REPORT_AN_ISSUE_PAGE] = 'Report an Issue';
    static readonly [CLIENT_PATHS.LOGOUT] = 'You have been logged out.';
    static readonly [CLIENT_PATHS.LOGIN] = '';

    static readonly [CLIENT_PATHS.CAPITAL_ONE_HOME_PAGE] = 'Capital One';
    static readonly [CLIENT_PATHS.MY_ACCOUNT_PAGE] = 'My Account';
}

export const PATH_TO_MENU_ITEM_NAME_MAP: Record<string, string> = Object.freeze({
    ...Object.fromEntries(Object.entries(PATH_TO_PAGE_NAME_MAP)),

    [CLIENT_PATHS.HOME_PAGE]: 'Home',

    [CLIENT_PATHS.SELECT_PARTNER_PAGE]: PATH_TO_PAGE_NAME_MAP[CLIENT_PATHS.DOWNLOAD_REPORTS_PAGE],
    [CLIENT_PATHS.SELECT_REPORT_CATEGORY_PAGE]: PATH_TO_PAGE_NAME_MAP[CLIENT_PATHS.DOWNLOAD_REPORTS_PAGE],

    [CLIENT_PATHS.CREATE_PARTNER_PAGE]: PATH_TO_PAGE_NAME_MAP[CLIENT_PATHS.MANAGE_PARTNERS_PAGE],
    [CLIENT_PATHS.EDIT_PARTNER_PAGE]: PATH_TO_PAGE_NAME_MAP[CLIENT_PATHS.MANAGE_PARTNERS_PAGE],

    [CLIENT_PATHS.CREATE_REPORT_CATEGORY_PAGE]: PATH_TO_PAGE_NAME_MAP[CLIENT_PATHS.MANAGE_REPORT_CATEGORIES_PAGE],
    [CLIENT_PATHS.EDIT_REPORT_CATEGORY_PAGE]: PATH_TO_PAGE_NAME_MAP[CLIENT_PATHS.MANAGE_REPORT_CATEGORIES_PAGE],

    [CLIENT_PATHS.CREATE_FREQUENCY_TYPE_PAGE]: PATH_TO_PAGE_NAME_MAP[CLIENT_PATHS.MANAGE_FREQUENCY_TYPES_PAGE],
    [CLIENT_PATHS.EDIT_FREQUENCY_TYPE_PAGE]: PATH_TO_PAGE_NAME_MAP[CLIENT_PATHS.MANAGE_FREQUENCY_TYPES_PAGE],

    [CLIENT_PATHS.CREATE_USER_PAGE]: PATH_TO_PAGE_NAME_MAP[CLIENT_PATHS.MANAGE_EXTERNAL_USERS_PAGE],
    [CLIENT_PATHS.EDIT_USER_PAGE]: PATH_TO_PAGE_NAME_MAP[CLIENT_PATHS.MANAGE_EXTERNAL_USERS_PAGE],

    [CLIENT_PATHS.MANAGE_FREQUENTLY_ASKED_QUESTIONS_PAGE]: 'Manage FAQs',
    [CLIENT_PATHS.CREATE_FREQUENTLY_ASKED_QUESTION_PAGE]: 'Manage FAQs',
    [CLIENT_PATHS.EDIT_FREQUENTLY_ASKED_QUESTION_PAGE]: 'Manage FAQs',

    [CLIENT_PATHS.CUSTOMER_INVOICE_FORM_PAGE]: PATH_TO_PAGE_NAME_MAP[CLIENT_PATHS.SELECT_OPERATIONAL_FORM_PAGE],
    [CLIENT_PATHS.CREATE_CUSTOMER_INVOICE_PAGE]: PATH_TO_PAGE_NAME_MAP[CLIENT_PATHS.SELECT_OPERATIONAL_FORM_PAGE],
    [CLIENT_PATHS.EDIT_CUSTOMER_INVOICE_PAGE]: PATH_TO_PAGE_NAME_MAP[CLIENT_PATHS.SELECT_OPERATIONAL_FORM_PAGE],

    [CLIENT_PATHS.WALMART_DISPUTE_FORM_PAGE]: PATH_TO_PAGE_NAME_MAP[CLIENT_PATHS.SELECT_OPERATIONAL_FORM_PAGE],
    [CLIENT_PATHS.CREATE_WALMART_DISPUTE_PAGE]: PATH_TO_PAGE_NAME_MAP[CLIENT_PATHS.SELECT_OPERATIONAL_FORM_PAGE],
    [CLIENT_PATHS.EDIT_WALMART_DISPUTE_PAGE]: PATH_TO_PAGE_NAME_MAP[CLIENT_PATHS.SELECT_OPERATIONAL_FORM_PAGE],

    [CLIENT_PATHS.DASHBOARDS_SELECT_PARTNER_PAGE]: PATH_TO_PAGE_NAME_MAP[CLIENT_PATHS.DASHBOARDS_SELECT_PAGE],
    [CLIENT_PATHS.DASHBOARDS_SELECT_PAGE]: PATH_TO_PAGE_NAME_MAP[CLIENT_PATHS.THOUGHTSPOT_LIVEBOARD_PAGE],
    [CLIENT_PATHS.DASHBOARDS_SELECT_PAGE]: PATH_TO_PAGE_NAME_MAP[CLIENT_PATHS.RSTUDIO_LIVEBOARD_PAGE],

    [CLIENT_PATHS.LOGOUT]: 'Log Out'
});

import { Router } from '@reach/router';
import { GrvProvider } from '@c1/gravity-react';
import NotFoundPage from 'src/pages/NotFoundPage/NotFoundPage';
import PageTemplate from 'src/pages/PageTemplate/PageTemplate';
import { FunctionComponentReturnType } from 'src/types/sharedReact';
import { CLIENT_PATHS } from 'src/utils/routing/routes';
import AuthenticationProvider from 'src/contextProviders/AuthenticationProvider/AuthenticationProvider';
import NavigationPaneProvider from 'src/contextProviders/NavigationPaneProvider/NavigationPaneProvider';
import EnvironmentProvider from 'src/contextProviders/EnvironmentProvider/EnvironmentProvider';
import { pageMetadatas, } from 'src/utils/routing/pageMetadatas';
import { EMPTY_PAGE_METADATA_PAGE_TYPES, PageMetadata } from 'src/utils/routing/PageMetadata';
import { FEATURES } from 'src/utils/feature';

function App(): FunctionComponentReturnType {
    return (
        <AuthenticationProvider>
            <NavigationPaneProvider>
                <EnvironmentProvider>
                    <GrvProvider autoGenerateId>
                        <Router basepath={CLIENT_PATHS.HOME_PAGE}>
                            {
                                Object.values(pageMetadatas).map((
                                    {
                                        Page,
                                        path,
                                        requiredFeatures,

                                        // TODO: THESE ARE DEPRECATED AND SHOULD BE REMOVED
                                        isAdminPage,
                                        isOperationPage,
                                        isReportPage,
                                        isDashboardPage
                                    }: PageMetadata
                                ) => {
                                    return <PageTemplate
                                        key={Page.name}
                                        Page={Page}
                                        path={path}
                                        requiredFeatures={requiredFeatures}

                                        // TODO: THESE ARE DEPRECATED AND SHOULD BE REMOVED
                                        isAdminPage={isAdminPage}
                                        isOperationPage={isOperationPage}
                                        isReportPage={isReportPage}
                                        isDashboardPage={isDashboardPage}
                                    />;
                                })
                            }

                            <PageTemplate
                                Page={NotFoundPage}
                                requiredFeatures={[FEATURES.NO_FEATURE_REQUIRED]}
                                default

                                // TODO: THESE ARE DEPRECATED AND SHOULD BE REMOVED
                                {...EMPTY_PAGE_METADATA_PAGE_TYPES}
                            />
                        </Router>
                    </GrvProvider>
                </EnvironmentProvider>
            </NavigationPaneProvider>
        </AuthenticationProvider>
    );
}

export default App;

import { User } from 'src/types/user';
import { ADGroup } from 'src/types/adGroup';
import { Feature, FEATURES } from 'src/utils/feature';
import _ from 'lodash';
import { GetPartnersResponse } from 'src/types/apiResponse';
export class AuthenticatedUser implements User {
    userId: string;
    firstName: string;
    lastName: string;
    phone: string | null;
    email: string;
    isExternalUser: boolean;

    features: Feature[];
    partners: string[];

    partnerAccess: GetPartnersResponse;

    // TODO: THESE ARE DEPRECATED AND SHOULD BE REMOVED
    accessGroups: ADGroup[];
    isAdmin: boolean;
    isOperTeam: boolean;

    constructor(user: User, partnerAccess: GetPartnersResponse | null) {
        this.userId = user.userId;
        this.firstName = user.firstName;
        this.lastName = user.lastName;
        this.phone = user.phone;
        this.email = user.email;
        this.isExternalUser = user.isExternalUser;

        this.features = user.features;
        this.partners = user.partners;
        if(partnerAccess != null) {
            this.partnerAccess = partnerAccess;
        }

        // TODO: THESE ARE DEPRECATED AND SHOULD BE REMOVED
        this.accessGroups = user.accessGroups;
        this.isAdmin = user.isAdmin;
        this.isOperTeam = user.isOperTeam;
    }

    isInternalAdmin(): boolean {
        return this.features.includes(FEATURES.INTERNAL_ADMIN)
            // TODO: THIS IS DEPRECATED AND SHOULD BE REMOVED
            || this.isAdmin;
    }

    hasAccessToFeature(targetFeatures: Feature[]): boolean {
        if (this.isInternalAdmin())
            return true;

        for (let i = 0; i < targetFeatures.length; i++) {
            if (this.features.includes(targetFeatures[i]))
                return true;
        }

        return false;
    }

    hasAccessToAtLeastOneOfTargetFeatures(targetFeatures: Feature[][]): boolean {
        for (let i = 0; i < targetFeatures.length; i++) {
            if (this.hasAccessToFeature(targetFeatures[i])) return true;
        }

        return false;
    }

    // TODO: THIS IS DEPRECATED AND SHOULD BE REMOVED
    private hasDeprecatedRoles(): boolean {
        return Boolean(this.accessGroups.length) || this.isOperTeam;
    }

    // TODO: THIS IS DEPRECATED AND SHOULD BE REMOVED
    hasDeprecatedAccessToDeprecatedPageType(
        isAdminPage: boolean,
        isReportPage: boolean,
        isDashboardPage: boolean,
        isOperationPage: boolean
    ): boolean {
        if (!this.hasDeprecatedRoles()) return false;

        const isReportUserAndOnReportPage: boolean = Boolean(isReportPage && this.hasDeprecatedAccessToReports());
        const isDashboardUserAndOnDashboardPage: boolean = Boolean(isDashboardPage && this.hasDeprecatedAccessToDashboards());
        const isUserOperTeamAndOnOperPage: boolean = Boolean(isOperationPage && this.isOperTeam);
        const isOrdinaryPageInDeprecatedParadigm: boolean = !isAdminPage && !isReportPage && !isDashboardPage && !isOperationPage;

        return isReportUserAndOnReportPage ||
            isDashboardUserAndOnDashboardPage ||
            isUserOperTeamAndOnOperPage ||
            isOrdinaryPageInDeprecatedParadigm;
    }

    hasHasAccessToAccessTypeAndPartnerIfRequired(
        requiredAccessTypeCode: string | undefined,
        requiredPartnerCode: string | undefined
    ): boolean {
        if (this.isInternalAdmin()) return true;

        if (!requiredAccessTypeCode && !requiredPartnerCode) return true;

        if (requiredAccessTypeCode && !requiredPartnerCode) return this.hasAccessToAccessType(requiredAccessTypeCode);

        if (!requiredAccessTypeCode && requiredPartnerCode) return this.hasAccessToPartner(requiredPartnerCode);

        // TODO: THIS IS DEPRECATED AND SHOULD BE REMOVED
        if (
            requiredAccessTypeCode &&
            requiredPartnerCode &&
            this.hasDeprecatedRoles() &&
            this.hasDeprecatedAccessToAccessTypeAndPartner(requiredAccessTypeCode, requiredPartnerCode)
        ) {
            return true;
        }

        if (requiredAccessTypeCode && requiredPartnerCode)
            return this.hasAccessToAccessTypeAndPartner(requiredAccessTypeCode, requiredPartnerCode);

        return false;
    }

    private hasAccessToAccessTypeAndPartner(accessTypeCode: string, partnerCode: string) {
        return this.isInternalAdmin() ||
            (this.hasAccessToAccessType(accessTypeCode) && this.hasAccessToPartner(partnerCode));
    }

    private hasAccessToAccessType(accessTypeCode: string): boolean {
        if (this.hasDeprecatedRoles() && this.hasDeprecatedAccessToAccessType(accessTypeCode)) return true; // TODO: THIS LINE IS DEPRECATED AND SHOULD BE REMOVED

        return this.isInternalAdmin() || !(accessTypeCode === 'INT' && this.isExternalUser);
    }

    private hasAccessToPartner(partnerCode: string): boolean {
        if (this.hasDeprecatedRoles() && this.hasDeprecatedAccessToPartner(partnerCode)) return true; // TODO: THIS LINE IS DEPRECATED AND SHOULD BE REMOVED

        const ALL_PARTNERS_ACCESS_CODE = 'ALL';

        return this.partners.includes(partnerCode) ||
            this.partners.includes(ALL_PARTNERS_ACCESS_CODE) ||
            this.isInternalAdmin();
    }

    // TODO: THIS IS DEPRECATED AND SHOULD BE REMOVED
    private hasDeprecatedAccessToAccessTypeAndPartner(accessTypeCode: string, partnerCode: string): boolean {
        return this.isInternalAdmin() || _.some(this.accessGroups, { code: accessTypeCode, partnerCode: partnerCode });
    }

    // TODO: THIS IS DEPRECATED AND SHOULD BE REMOVED
    private hasDeprecatedAccessToAccessType(accessTypeCode: string): boolean {
        return this.isInternalAdmin() || _.some(this.accessGroups, { code: accessTypeCode });
    }

    // TODO: THIS IS DEPRECATED AND SHOULD BE REMOVED
    private hasDeprecatedAccessToPartner(partnerCode: string): boolean {
        return this.isInternalAdmin() || _.some(this.accessGroups, { partnerCode: partnerCode });
    }

    // TODO: THIS IS DEPRECATED AND SHOULD BE REMOVED
    private hasDeprecatedAccessToReports(): boolean {
        return this.isInternalAdmin() || _.some(this.accessGroups, { userType: 'RPT' });
    }

    // TODO: THIS IS DEPRECATED AND SHOULD BE REMOVED
    private hasDeprecatedAccessToDashboards(): boolean {
        return this.isInternalAdmin() || _.some(this.accessGroups, { userType: 'DSH' });
    }
}

import { AuthenticatedUser } from 'src/utils/AuthenticatedUser';
import { MenuItem } from 'src/components/NavigationPane/NavMenuItem';
import { Feature, FEATURES } from 'src/utils/feature';
import RouteBuilder from 'src/utils/routing/RouteBuilder';

export function isUserAuthorizedToSeePage(
    user: AuthenticatedUser | null,
    requiredFeatures: Feature[],

    routeBuilder: RouteBuilder,

    isLogoutPage: boolean,
    isLoginPage: boolean,

    // TODO: THESE ARE DEPRECATED AND SHOULD BE REMOVED
    isAdminPage: boolean,
    isReportPage: boolean,
    isDashboardPage: boolean,
    isOperationPage: boolean
) {
    if (isLogoutPage || isLoginPage) {
        return true;
    }

    if (!user) {
        return false;
    }

    if (user.isInternalAdmin()) {
        return true;
    }

    if (requiredFeatures.includes(FEATURES.NO_FEATURE_REQUIRED)) {
        return true;
    }

    if (!user.hasHasAccessToAccessTypeAndPartnerIfRequired(routeBuilder.getAccessTypeCode(), routeBuilder.getPartnerCode())) {
        return false;
    }

    if (user.hasDeprecatedAccessToDeprecatedPageType(isAdminPage, isReportPage, isDashboardPage, isOperationPage)) return true; // TODO: THIS LINE IS DEPRECATED AND SHOULD BE REMOVED

    if (user.hasAccessToFeature(requiredFeatures)) return true;

    return false;
}

export function isUserAuthorizedToSeeMenuItem(user: AuthenticatedUser | null, menuItem: MenuItem): boolean {
    if (!user) {
        return false;
    }

    if (user.isInternalAdmin()) {
        return true;
    }

    const requiredFeatures: Feature[] = menuItem.metadata.requiredFeatures;
    if (requiredFeatures.includes(FEATURES.NO_FEATURE_REQUIRED)) {
        return true;
    }

    // TODO: THESE ARE DEPRECATED AND SHOULD BE REMOVED
    const isReportPage: boolean = menuItem.metadata.isReportPage;
    const isDashboardPage: boolean = menuItem.metadata.isDashboardPage;
    const isOperationPage: boolean = menuItem.metadata.isOperationPage;
    const isAdminPage: boolean = menuItem.metadata.isAdminPage;
    if (user.hasDeprecatedAccessToDeprecatedPageType(isAdminPage, isReportPage, isDashboardPage, isOperationPage)) return true; // TODO: THIS LINE IS DEPRECATED AND SHOULD BE REMOVED

    const nestedMenuItems: MenuItem[] | undefined = menuItem.menuItems;
    if (nestedMenuItems) {
        if (
            user.hasAccessToAtLeastOneOfTargetFeatures(
                nestedMenuItems.map((x: MenuItem): Feature[] => x.metadata.requiredFeatures)
            )
        ) return true;
    }

    if (user.hasAccessToFeature(requiredFeatures)) return true;

    return false;
}

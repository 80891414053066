import { FunctionComponent } from 'react';
import { ClientPath, PageName, PATH_TO_MENU_ITEM_NAME_MAP, PATH_TO_PAGE_NAME_MAP } from 'src/utils/routing/routes';
import { Feature } from 'src/utils/feature';

export type PageMetadataPageTypes = Pick<PageMetadata, 'isAdminPage' | 'isReportPage' | 'isDashboardPage' | 'isOperationPage'>; // TODO: THIS LINE IS DEPRECATED AND SHOULD BE REMOVED

interface PageMetadataOptionalParameters {
    deprecatedAuthorizations?: Partial<PageMetadataPageTypes>;  // TODO: THIS LINE IS DEPRECATED AND SHOULD BE REMOVED
    menuIcon?: string;
}

export class PageMetadata {
    readonly Page: FunctionComponent;
    readonly path: ClientPath;
    readonly pageName: PageName;
    readonly menuItemName: string;
    readonly menuIcon: string | undefined;

    readonly requiredFeatures: Feature[];

    // TODO: THESE ARE DEPRECATED AND SHOULD BE REMOVED
    readonly isAdminPage: boolean;
    readonly isReportPage: boolean;
    readonly isDashboardPage: boolean;
    readonly isOperationPage: boolean;

    constructor(
        Page: FunctionComponent,
        path: ClientPath,
        requiredFeatures: Feature[],
        optionalParameters: PageMetadataOptionalParameters = {}
    ) {
        this.Page = Page;
        this.path = path;
        this.pageName = PATH_TO_PAGE_NAME_MAP[path];
        this.menuItemName = PATH_TO_MENU_ITEM_NAME_MAP[path];

        this.requiredFeatures = requiredFeatures;

        this.menuIcon = optionalParameters.menuIcon || undefined;

        // TODO: THESE ARE DEPRECATED AND SHOULD BE REMOVED
        this.isAdminPage = Boolean(optionalParameters.deprecatedAuthorizations?.isAdminPage);
        this.isReportPage = Boolean(optionalParameters.deprecatedAuthorizations?.isReportPage);
        this.isDashboardPage = Boolean(optionalParameters.deprecatedAuthorizations?.isDashboardPage);
        this.isOperationPage = Boolean(optionalParameters.deprecatedAuthorizations?.isOperationPage);
    }
}

// TODO: THESE ARE DEPRECATED AND SHOULD BE REMOVED
export const EMPTY_PAGE_METADATA_PAGE_TYPES: PageMetadataPageTypes = {
    isAdminPage: false,
    isReportPage: false,
    isDashboardPage: false,
    isOperationPage: false
};
